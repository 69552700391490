import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyType } from '@house-id/houseid-types/dist/property';
import { skipToken } from '@reduxjs/toolkit/query';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { Currency } from '../../../../../../../../../utils/string';
import useGetCurrentProperty from '../../../../../../../hooks/useCurrentProperty';
import {
  useGetPropertySpecificationValuesQuery,
  useUpdatePropertyMutation,
  useUpdatePropertySpecificationMutation,
} from '../../../../../../../api/api.property';
import {
  deductionsApi,
  useGetDeductionSettingsQuery,
} from '../api/api.deductions';
import {
  useGetPropertyFinanceSaleSummaryQuery,
  useUpdatePropertyFinanceInformationMutation,
} from '../../../../../api/api.propertyFinance';
import { OTHER_VALUATION_REALTOR_CODE } from '../../../../Valuation/constants.valuation';
import { useNavigateBackOr } from '../../../../../../../../../utils/routes';
import { getHomePath } from '../../../../../../../navigation/navigation.property';
import HomeLayout from '../../../../../../../pages/Home/components/HomeLayout';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDTextField from '../../../../../../../../../components/HIDTextField';
import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';
import HIDFormDatePicker from '../../../../../../../../../components/datePicker/HIDFormDatePicker';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../../../hooks/useDialog';
import { HIDApiTags } from '../../../../../../../../../api/HIDApiTags';

type UpdateSalesInformationForm = {
  propertyAddress?: string;
  propertyDesignation?: string;
  saleDate?: string;
  salePrice?: number;

  purchaseDate?: string;
  purchasePrice?: number;

  cooperativeName?: string;
  cooperativeOrganizationNumber?: string;
  apartmentNumber?: string;

  remindingCapitalGainsTaxDeferralPaybackAmount?: number;
};

const UpdateDeclarationSettings: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'declarations']);
  const theme = useTheme();

  const { data: property } = useGetCurrentProperty();

  const isHouseOrUnknown = property?.type === PropertyType.HOUSE || property?.type === PropertyType.UNKNOWN;
  const isApartment = property?.type === PropertyType.CONDOMINIUM;

  const salesInformationReadonly = Boolean(property?.saleInformation?.readonly);

  const {
    data: propertySpecifications,
  } = useGetPropertySpecificationValuesQuery(property ? { propertyId: property.id } : skipToken);

  const {
    data: deductionSettings,
  } = useGetDeductionSettingsQuery(property ? { propertyId: property.id } : skipToken);

  const {
    data: propertySalesSummary,
  } = useGetPropertyFinanceSaleSummaryQuery(property ? { propertyId: property.id } : skipToken);

  const [
    updateProperty,
    { isLoading: propertyIsUpdating },
  ] = useUpdatePropertyMutation();

  const [
    updatePropertySpecification,
    { isLoading: propertySpecificationIsUpdating },
  ] = useUpdatePropertySpecificationMutation();

  const [
    updatePropertyFinanceInformation,
    { isLoading: propertyFinanceInformationIsUpdating },
  ] = useUpdatePropertyFinanceInformationMutation();

  const isSaving = propertySpecificationIsUpdating
    || propertyIsUpdating
    || propertyFinanceInformationIsUpdating;

  const handleGoBack = () => navigateBackOr(property?.id ? getHomePath({ propertyId: property.id }) : undefined);

  const handleSubmit = (values: UpdateSalesInformationForm) => {
    if (!property?.id) {
      return;
    }

    const updatePropertySalesPromise = !salesInformationReadonly && (values.saleDate || values.salePrice)
      ? updateProperty({
        id: property.id,
        saleInformation: {
          contractDate: values.saleDate,
          realtorId: OTHER_VALUATION_REALTOR_CODE,
          salePrice: values.salePrice
            ? {
              amount: values.salePrice,
              currency: Currency.code,
            }
            : {
              amount: 0,
              currency: Currency.code,
            },
        },
      })
        .unwrap()
      : Promise.resolve();

    const propertySpecificationFields = {
      purchaseDate: values.purchaseDate,
      purchasePrice: values.purchasePrice
        ? {
          amount: values.purchasePrice,
          currency: Currency.code,
        }
        : {
          amount: 0,
          currency: Currency.code,
        },
      ...(
        isApartment
          ? {
            cooperativeName: values.cooperativeName,
            cooperativeOrganizationNumber: values.cooperativeOrganizationNumber,
            apartmentNumber: values.apartmentNumber,
          }
          : {}
      ),
    };

    const updatePropertySpecificationPromise = updatePropertySpecification({
      propertyId: property.id,
      fields: propertySpecificationFields,
    })
      .unwrap();

    const updatePropertyFinanceInformationPromise = updatePropertyFinanceInformation({
      propertyId: property.id,
      financeInformation: {
        remindingCapitalGainsTaxDeferralPaybackAmount: values.remindingCapitalGainsTaxDeferralPaybackAmount || 0,
      },
    })
      .unwrap();

    return Promise
      .all([
        updatePropertySalesPromise,
        updatePropertySpecificationPromise,
        updatePropertyFinanceInformationPromise,
      ])
      .then(() => {
        dispatch(deductionsApi.util.invalidateTags([HIDApiTags.DEDUCTION_OVERVIEW]));
        handleGoBack();
      });
  };

  const schema = Yup.object().shape({});

  const formik = useFormik<UpdateSalesInformationForm>({
    initialValues: {
      propertyAddress: `${property?.address?.streetAddress}, ${property?.address?.addressLocality}`,
      propertyDesignation: propertySpecifications?.propertyDesignation,
      saleDate: property?.saleInformation?.contractDate,
      salePrice: property?.saleInformation?.salePrice?.amount,

      purchaseDate: propertySpecifications?.purchaseDate,
      purchasePrice: propertySpecifications?.purchasePrice?.amount,

      cooperativeName: propertySpecifications?.cooperativeName,
      cooperativeOrganizationNumber: propertySpecifications?.cooperativeOrganizationNumber,
      apartmentNumber: propertySpecifications?.apartmentNumber,

      remindingCapitalGainsTaxDeferralPaybackAmount: propertySalesSummary?.taxCalculation?.remindingCapitalGainsTaxDeferralPaybackAmount,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const [openDeclarationHelpQuestionsWizardDialog] = useDialog(DialogNames.DECLARATION_HELP_QUESTIONS_WIZARD);

  return (
    <HomeLayout
      SideColumn={<div />}
      title={t('declarations:declarations_edit_sales_information')}
    >
      <Stack sx={{ gap: 3 }}>
        <Stack
          sx={{
            gap: 1,
            padding: 2,
            borderRadius: BorderRadius.sm,
            backgroundColor: theme.palette.skattio.lightest,
          }}
        >
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_if_you_choose_to_make_a_change')}
          </HIDTypography>
          <HIDTypography>
            {t('declarations:declarations_be_aware_that_any_adjustment_in_the_field_below_may_adjust_deduction')}
          </HIDTypography>
        </Stack>
        <HIDTextField
          disabled
          color="skattio"
          label={t('declarations:declarations_you_have_sold_a_home_on')}
          showHelperText={false}
          value={formik.values.propertyAddress}
          onChange={({ target: { value } }) => formik.setFieldValue('propertyAddress', value)}
        />
        {isHouseOrUnknown && (
          <HIDTextField
            disabled
            color="skattio"
            label={t('declarations:declarations_property_designation')}
            showHelperText={false}
            value={formik.values.propertyDesignation}
            onChange={({ target: { value } }) => formik.setFieldValue('propertyDesignation', value)}
          />
        )}
        <HIDFormDatePicker
          color="skattio"
          disabled={salesInformationReadonly}
          label={t('declarations:declarations_sale_date')}
          showHelperText={false}
          value={formik.values.saleDate ? new Date(formik.values.saleDate) : undefined}
          onChange={(value) => formik.setFieldValue('saleDate', value?.toISOString())}
        />
        <HIDCurrencyField
          color="skattio"
          disabled={salesInformationReadonly}
          label={t('declarations:declarations_sales_price_according_to_purchase_contract')}
          showHelperText={false}
          value={formik.values.salePrice?.toString()}
          onChange={({ target: { value } }) => formik.setFieldValue('salePrice', value ? Number.parseInt(value, 10) : undefined)}
        />
        <HIDFormDatePicker
          color="skattio"
          label={t('declarations:declarations_bought_the_home')}
          showHelperText={false}
          value={formik.values.purchaseDate ? new Date(formik.values.purchaseDate) : undefined}
          onChange={(value) => formik.setFieldValue('purchaseDate', value?.toISOString())}
        />
        <HIDCurrencyField
          color="skattio"
          label={t('declarations:declarations_purchase_price_according_to_purchase_contract')}
          value={formik.values.purchasePrice?.toString()}
          onChange={({ target: { value } }) => formik.setFieldValue('purchasePrice', value ? Number.parseInt(value, 10) : undefined)}
        />
        {isApartment && (
          <>
            <HIDCurrencyField
              color="skattio"
              label={t('declarations:declarations_fill_in_the_name_of_the_association')}
              showHelperText={false}
              value={formik.values.cooperativeName}
              onChange={({ target: { value } }) => formik.setFieldValue('cooperativeName', value)}
            />
            <HIDTextField
              color="skattio"
              label={t('declarations:declarations_fill_in_the_organization_number')}
              showHelperText={false}
              value={formik.values.cooperativeOrganizationNumber}
              onChange={({ target: { value } }) => formik.setFieldValue('cooperativeOrganizationNumber', value)}
            />
            <HIDTextField
              color="skattio"
              label={t('declarations:declarations_fill_in_the_apartment_number')}
              showHelperText={false}
              value={formik.values.apartmentNumber}
              onChange={({ target: { value } }) => formik.setFieldValue('apartmentNumber', value)}
            />
          </>
        )}
        <Stack sx={{ gap: 2 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declaration_help_enter_your_previous_deferral_amount')}
          </HIDTypography>
          <HIDCurrencyField
            color="skattio"
            label={t('declarations:declaration_help_deferral_amount')}
            showHelperText={false}
            value={formik.values.remindingCapitalGainsTaxDeferralPaybackAmount?.toString() || ''}
            onChange={({ target: { value } }) =>
              formik.setFieldValue('remindingCapitalGainsTaxDeferralPaybackAmount', value ? Number.parseInt(value, 10) : undefined)}
          />
        </Stack>
        <Stack sx={{ gap: 2 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_were_you_the_first_owner_of_the_home')}
          </HIDTypography>
          <HIDTypography>
            {deductionSettings?.answers?.currentOwnerIsFirstOwner ? t('common:yes') : t('common:no')}
          </HIDTypography>
        </Stack>
        {deductionSettings?.answers?.currentOwnerIsFirstOwner && (
          <Stack sx={{ gap: 2 }}>
            <HIDTypography variant="subtitle1">
              {t('declarations:declarations_did_you_buy_the_home_when_converting_from_a_rental_property')}
            </HIDTypography>
            <HIDTypography>
              {deductionSettings?.answers?.propertyWasConvertedFromRentalToOwnership ? t('common:yes') : t('common:no')}
            </HIDTypography>
          </Stack>
        )}
        {deductionSettings?.answers?.currentOwnerIsFirstOwner === false && (
          <Stack sx={{ gap: 2 }}>
            <HIDTypography variant="subtitle1">
              {t('declarations:declarations_did_you_receive_the_home_as_an_inheritance_gift_or_property_division')}
            </HIDTypography>
            {deductionSettings?.answers?.propertyWasConvertedFromRentalToOwnership ? t('common:yes') : t('common:no')}
          </Stack>
        )}
        {deductionSettings?.answers?.propertyWasInheritedOrGifted && (
          <Stack sx={{ gap: 2 }}>
            <HIDTypography variant="subtitle1">
              {t('declarations:declarations_was_the_previous_owner_the_first_owner_of_the_home')}
            </HIDTypography>
            {deductionSettings?.answers?.previousOwnerWasFirstOwner ? t('common:yes') : t('common:no')}
          </Stack>
        )}
        <HIDButton
          Icon={Edit}
          color="skattio-secondary"
          sx={{ alignSelf: 'center' }}
          onClick={() => openDeclarationHelpQuestionsWizardDialog()}
        >
          {t('declarations:declarations_edit_answers')}
        </HIDButton>
        <Divider />
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <HIDButton
            color="skattio-secondary"
            onClick={handleGoBack}
          >
            {t('common:cancel')}
          </HIDButton>
          <HIDButton
            color="skattio-primary"
            loading={isSaving}
            onClick={() => formik.submitForm()}
          >
            {t('common:save')}
          </HIDButton>
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default UpdateDeclarationSettings;
