import React, {
  FC,
} from 'react';
import { useLocation } from 'react-router';

import HIDLayout from './HIDLayout';
import DialogsManager from '../dialogs/dialogsManager/DialogsManager';
import { useGetTokenDataQuery } from '../../modules/Auth/api/api.user';
import {
  useGetActiveAuthUser,
} from '../../external-services/firebase';
import NotAuthorizedHeader from '../../modules/Forum/components/NotAuthorizedHeader';
import Menu from '../../modules/Property/pages/Home/components/Menu';
import useGetCurrentPropertyId from '../../modules/Property/hooks/useGetCurrentPropertyId';
import Header from '../../modules/Property/pages/Home/components/Header';
import TrialPeriodHeaderBanner from '../../modules/Property/pages/Home/components/TrialPeriodHeaderBanner';
import PartnersHeader from '../../modules/Partners/components/PartnersHeader';
import PartnersMenu from '../../modules/Partners/components/PartnersMenu';
import useGetSubscriptionPlanTrialPeriodInfo from '../../modules/SubscriptionPlans/hooks/useGetSubscriptionTrialPeriodInfo';
import HeaderLoader from '../../modules/Property/pages/Home/components/HeaderLoader';
import usePageTracking from '../../modules/Analytics/hooks/usePageTracking';
import { useRouteQueryParams } from '../../utils/routes';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';

const EXCLUDED_BANNER_FROM_PAGES = [
  '/consumables/*',
];

const HIDRootLayout: FC = () => {
  const { user: currentUser, isLoading: currentUserIsLoading, isPartnerAuth } = useGetActiveAuthUser();
  usePageTracking();

  const location = useLocation();

  const { isDownSm } = useBreakPointsSizes();

  const { headless = false } = useRouteQueryParams<{ headless?: boolean }>();

  const {
    data: tokenData,
    isLoading: isLoadingTokenData,
    isUninitialized: isUninitializedTokenData,
  } = useGetTokenDataQuery({}, { skip: currentUserIsLoading || !currentUser });

  const {
    data: propertyId,
    isLoading: isLoadingCurrentProperty,
    isUninitialized: isUninitializedCurrentProperty,
  } = useGetCurrentPropertyId(undefined, { skip: !currentUser || isPartnerAuth });

  const {
    data: { isEnabled } = {},
  } = useGetSubscriptionPlanTrialPeriodInfo(undefined, { skip: !currentUser || isPartnerAuth });

  const isLoading = currentUserIsLoading || isLoadingTokenData || isLoadingCurrentProperty;

  const isUninitialized = currentUser && (isUninitializedTokenData || (!isPartnerAuth && isUninitializedCurrentProperty));

  const isAuthorized = Boolean(currentUser)
    && (isPartnerAuth || (tokenData?.userId));

  const HeaderComponent = isLoading || isUninitialized
    ? HeaderLoader
    : !isAuthorized
      ? NotAuthorizedHeader
      : isPartnerAuth
        ? PartnersHeader
        : Header;

  const MenuComponent = isAuthorized && (propertyId && Boolean(tokenData?.properties?.length)) && !isPartnerAuth
    ? Menu
    : isPartnerAuth
      ? PartnersMenu
      : undefined;

  const skipHeaderBanner = EXCLUDED_BANNER_FROM_PAGES.some((page) => location.pathname.match(page));

  const TrialPeriodHeaderBannerComponent = isAuthorized && !isPartnerAuth && isEnabled && !isDownSm && !skipHeaderBanner
    ? TrialPeriodHeaderBanner
    : undefined;

  return (
    <>
      <HIDLayout
        Header={headless ? undefined : HeaderComponent}
        HeaderBanner={TrialPeriodHeaderBannerComponent}
        Menu={MenuComponent}
      />
      <DialogsManager />
    </>
  );
};

export default HIDRootLayout;
