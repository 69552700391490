import { DeductionChatContext } from '@house-id/houseid-types/dist/deductions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '@house-id/houseid-types/dist/article';
import {
  Card,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../../../../../types/common';
import { useGetArticlesQuery } from '../../../../../../Content/modules/Article/api/api.article';
import DeductionArticle from './DeductionArticle';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../../../components/HIDLink';

type DeductionArticlesSectionProps = {
  context: DeductionChatContext;
};

const DeductionArticlesSection: FCC<DeductionArticlesSectionProps> = ({
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['deductions']);

  const {
    data: deductionArticles = [],
    isLoading,
    isFetching: isArticlesFetching,
  } = useGetArticlesQuery({
    pageSize: 5,
  });

  const isArticlesLoading = isArticlesFetching || isLoading;

  const articles = isArticlesLoading
    ? Array.from({ length: 5 }, (_, index) => ({ id: index })) as unknown as Article[]
    : deductionArticles;

  return articles.length
    ? (
      <Card sx={{ padding: 2, ...sx }}>
        <Stack>
          <HIDTypography variant="h6">
            {t('deductions:deductions_section_deductions_articles')}
          </HIDTypography>
          <Stack>
            {
              articles.map((article, index) => (
                <React.Fragment key={article.id}>
                  <DeductionArticle
                    article={article}
                    isLoading={isArticlesLoading}
                    sx={{ paddingY: theme.spacing(1.5) }}
                  />
                  {(articles.length !== index + 1) && (<Divider />)}
                </React.Fragment>
              ))
            }
          </Stack>
          <HIDLink
            color="skattio"
            href="#"
            label={t('deductions:deductions_section_open_more_articles')}
            target="_self"
            underline="none"
          />
        </Stack>
      </Card>
    ) : null;
};

export default DeductionArticlesSection;
