import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Deduction,
  AmountSpecificationType,
  DeductionTypeReceipt,
} from '@house-id/houseid-types/dist/deductions';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import ReactMarkdown from 'react-markdown';

import DeclarationTableRow from '../../../../../components/DeclarationTableRow';
import HIDTypography from '../../../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../../../constants/borderRadius';
import useGetCurrentPropertyId from '../../../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionsTypesQuery } from '../../../api/api.deductions';
import {
  CreateDeductionMode,
  CreateEditDeductionValues,
} from '../types';
import ReceiptsMissedAmount from './ReceiptsMissedAmount';
import HIDCurrencyField from '../../../../../../../../../../../components/HIDCurrencyField';
import HIDInfo from '../../../../../../../../../../../components/HIDInfo';
import DeductionCardContainer from './DeductionCardContainer';
import DeductableReceiptsPicker from './DeductableReceiptsPicker';
import HIDSection from '../../../../../../../../../../../components/HIDSection';
import DynamicContentSource from '../../../../../../../../Content/modules/DynamicContent/components/dynamicInputForm/components/DynamicContentSource';

type DeductionReceiptsAndAmountsProps = {
  createDeductionMode: CreateDeductionMode;
  type: string | undefined;
  formik: FormikProps<CreateEditDeductionValues>;
  deduction: Deduction | undefined,
  deductionTypeReceipts: Array<DeductionTypeReceipt>;
  onValueChanged: (field: string, value: any) => void,
  onConnectedReceiptsChange: (receipts: string[]) => void,
};

const DeductionReceiptsAndAmounts: FC<DeductionReceiptsAndAmountsProps> = ({
  type,
  formik,
  deduction,
  createDeductionMode,
  deductionTypeReceipts,
  onValueChanged,
  onConnectedReceiptsChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['deductions', 'forms_common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: deductionTypes,
  } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const currentType = deductionTypes?.find((deductionType) => deductionType.id === type || deductionType.id === formik.values.type);
  const hasType = Boolean(formik.values.type);
  const needsReceipts = Boolean(currentType?.needReceipts);

  const showReceiptsSummary = hasType && deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
    ? needsReceipts && Object.values(deduction.yearSummary).some((yearSummary) => yearSummary.totalAmount || yearSummary.totalReceiptAmount)
    : needsReceipts && Boolean(deduction && (deduction.summary.totalAmount || deduction.summary.totalReceiptAmount));

  const typeName = currentType?.name?.toLowerCase();
  const costPerDeductionTypeName = currentType?.name;

  const handleReceiptsChange = (receipt: Record<string, string[]>) => {
    const receiptIds = receipt[EntityType.RECEIPT] || [];
    onConnectedReceiptsChange(receiptIds);
  };

  const errorMessage = formik.touched.amount && formik.errors.amount ? formik.errors.amount : undefined;
  const amountHelperText = (
    <Stack>
      {errorMessage && (
        <Typography variant="inherit">
          {errorMessage}
        </Typography>
      )}
      <DynamicContentSource source={deduction?.source} />
    </Stack>
  );

  return (
    <DeductionCardContainer
      sx={
        !showReceiptsSummary ? { paddingY: theme.spacing(2.5) } : undefined
      }
    >
      <Stack gap={2} sx={[{ paddingX: theme.spacing(2.5) }, showReceiptsSummary && { paddingTop: theme.spacing(2.5) }]}>
        <HIDInfo
          isMarkdown
          description={createDeductionMode === CreateDeductionMode.RENOVATION_FAST_TRACK
            ? t('deductions:deduction_how_much_it_cost_description')
            : undefined}
          label={costPerDeductionTypeName}
          labelVariant="subtitle1"
        />
        <Stack>
          <HIDTypography>
            {currentType?.amountDescription || t('deductions:total_cost_of_action_description')}
          </HIDTypography>
          {Boolean(deductionTypeReceipts.length) && (
            <HIDTypography>
              {t('deductions:deduction_sales_expenses_retrieve_receipts', { typeName })}
            </HIDTypography>
          )}
        </Stack>
        <Stack>
          <HIDCurrencyField
            required
            backgroundColor={theme.palette.skattio.lightest}
            color="skattio"
            helperText={amountHelperText}
            inputProps={{ min: 0 }}
            label={costPerDeductionTypeName}
            value={formik.values.amount}
            onBlur={formik.handleBlur('amount')}
            onChange={(value) => onValueChanged('amount', value.target.value)}
          />
          {currentType?.amountHelpText
            ? (
              <HIDSection sx={{ backgroundColor: theme.palette.grey[200], padding: theme.spacing(1) }}>
                <HIDTypography variant="body1">
                  <ReactMarkdown components={{ p: 'div' }}>
                    {currentType.amountHelpText}
                  </ReactMarkdown>
                </HIDTypography>
              </HIDSection>
            ) : undefined}
        </Stack>
        {currentType?.needReceipts && (
          <Stack gap={2}>
            <Stack gap={0.5}>
              <HIDTypography variant="subtitle1">
                {t('deductions:deduction_sales_expenses_receipts_associated_with_action')}
              </HIDTypography>
              <HIDInfo
                isMarkdown
                description={t('deductions:deduction_sales_expenses_receipts_associated_with_action_description_modal')}
                label={t('deductions:deduction_sales_expenses_receipts_associated_with_action_description')}
                labelVariant="body1"
              />
            </Stack>
            <DeductableReceiptsPicker
              deductionCategoryId={deduction?.categoryId}
              deductionId={deduction?.id}
              deductionTypeId={deduction?.type}
              receiptConnections={deductionTypeReceipts}
              receiptInitialValues={{
                name: formik.values.name,
              }}
              selectedItems={formik.values.receiptConnections[EntityType.RECEIPT] || []}
              onSelect={(items) => handleReceiptsChange({ [EntityType.RECEIPT]: items })}
            />
          </Stack>
        )}
      </Stack>
      {showReceiptsSummary && (
        <Stack
          gap={1.5}
          padding={2.5}
          style={{
            backgroundColor: theme.palette.grey[200],
            borderBottomLeftRadius: BorderRadius.md,
            borderBottomRightRadius: BorderRadius.md,
          }}
        >
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {costPerDeductionTypeName}
            </HIDTypography>
            <DeclarationTableRow
              item={{
                id: 'summary',
                label: t('finances:amount'),
                value: Number(deduction?.summary.totalAmount || 0),
                withDecimals: false,
              }}
            />
          </Stack>
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {t('deductions:create_edit_deductions_connected_receipts_amount')}
            </HIDTypography>
            <DeclarationTableRow
              item={{
                id: 'summary',
                label: t('finances:amount'),
                value: deduction?.summary?.totalReceiptAmount || 0,
                withDecimals: false,
              }}
            />
          </Stack>
          <ReceiptsMissedAmount deduction={deduction} />
        </Stack>
      )}
    </DeductionCardContainer>
  );
};

export default DeductionReceiptsAndAmounts;
