import {
  Deduction,
  DeductionCreateRequest,
  DeductionUpdateRequest,
  DeductionOverview,
  DeductionType,
  DeductionChatContext,
  DeductionTypeReceipt,
  DeductionSupportRequest,
} from '@house-id/houseid-types/dist/deductions';
import { DeductionPropertySettings } from '@house-id/houseid-types/dist/deductions/deductionPropertySettings';
import { PropertyId } from '@house-id/houseid-types/dist/property';

import { propertyApi } from '../../../../../../../api/api.property';
import { HIDApiTags } from '../../../../../../../../../api/HIDApiTags';

export const deductionsApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeductionsTypes: builder.query<Array<DeductionType>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/deductions/types`,
    }),
    getDeductionsOverview: builder.query<DeductionOverview, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/deductions/overview`,
      providesTags: () => [HIDApiTags.DEDUCTION_OVERVIEW],
    }),
    getDeductions: builder.query<Array<Deduction>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/deductions`,
      providesTags: () => [HIDApiTags.DEDUCTION],
    }),
    getDeduction: builder.query<Deduction, PropertyId & { id: string }>({
      query: ({ propertyId, id }) => `/properties/${propertyId}/deductions/${id}`,
      providesTags: (result) => [{ type: HIDApiTags.DEDUCTION, id: result?.id }],
    }),
    getDeductionsConnectedReceipts: builder.query<Array<DeductionTypeReceipt>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/deductions/types/receipts`,
      providesTags: () => [{ type: HIDApiTags.DEDUCTION_RECEIPTS }],
    }),
    createDeduction: builder.mutation<Deduction, PropertyId & DeductionCreateRequest>({
      query: ({ propertyId, ...rest }) => ({
        url: `/properties/${propertyId}/deductions`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: () => [HIDApiTags.DEDUCTION_OVERVIEW, HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
    updateDeduction: builder.mutation<Deduction, PropertyId & { id: string } & DeductionUpdateRequest>({
      query: ({ propertyId, id, ...rest }) => ({
        url: `/properties/${propertyId}/deductions/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (result) => [
        { type: HIDApiTags.DEDUCTION, id: result?.id },
        HIDApiTags.DEDUCTION_OVERVIEW,
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
    deleteDeduction: builder.mutation<Deduction, PropertyId & { id: string }>({
      query: ({ propertyId, id }) => ({
        url: `/properties/${propertyId}/deductions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [HIDApiTags.DEDUCTION_OVERVIEW, HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
    deleteAllDeductions: builder.mutation<Deduction, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}/deductions`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        HIDApiTags.DEDUCTION_OVERVIEW,
        HIDApiTags.DEDUCTION,
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION,
        HIDApiTags.PRODUCT_ACTIVITY,
      ],
    }),
    getDeductionSettings: builder.query<DeductionPropertySettings, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/deductions/settings`,
      providesTags: () => [{ type: HIDApiTags.DEDUCTION_SETTINGS }],
    }),
    updateDeductionSettings: builder.mutation<void, PropertyId & DeductionPropertySettings>({
      query: ({ propertyId, answers }) => ({
        url: `/properties/${propertyId}/deductions/settings`,
        method: 'PATCH',
        body: { answers },
      }),
      invalidatesTags: () => [HIDApiTags.DEDUCTION_SETTINGS],
    }),
    createDeclarationHelpChatSession: builder.mutation<{ url: string }, PropertyId & { context: DeductionChatContext }>({
      query: ({ propertyId, context }) => ({
        url: `/properties/${propertyId}/deductions/chats/sessions`,
        method: 'POST',
        body: context,
      }),
    }),
    createDeclarationSupportTicket: builder.mutation<void, PropertyId & DeductionSupportRequest>({
      query: ({ propertyId, ...rest }) => ({
        url: `/properties/${propertyId}/deductions/support/requests`,
        method: 'POST',
        body: rest,
      }),
    }),
    createSkattioToken: builder.mutation<{ url: string; token: string }, PropertyId>({
      query: ({ propertyId }) => ({
        url: '/partner/skattio/token',
        method: 'PUT',
        body: { propertyId },
      }),
      invalidatesTags: () => [HIDApiTags.DEDUCTION_OVERVIEW],
    }),
  }),
});

export const {
  useGetDeductionsTypesQuery,
  useGetDeductionsOverviewQuery,
  useGetDeductionsConnectedReceiptsQuery,
  useGetDeductionsQuery,
  useGetDeductionQuery,
  useCreateDeductionMutation,
  useUpdateDeductionMutation,
  useDeleteDeductionMutation,
  useUpdateDeductionSettingsMutation,
  useGetDeductionSettingsQuery,
  useDeleteAllDeductionsMutation,
  useCreateDeclarationHelpChatSessionMutation,
  useCreateDeclarationSupportTicketMutation,
  useCreateSkattioTokenMutation,
  useLazyGetDeductionsOverviewQuery,
} = deductionsApi;
