import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Dialog,
  Icon as MUIIcon,
  IconButton,
  Stack,
  Tab,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import HIDButton from '../../../../../../../components/buttons/HIDButton';
import {
  FCC,
  HIDDialogProps,
  Icon,
} from '../../../../../../../types/common';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useGetEntityConnectionsQuery,
  usePatchEntityConnectionsMutation,
} from '../../../api/api.content';
import ManageDocumentsTab from './manageContentConnectionsTabs/ManageDocumentsTab';
import ManageReceiptsTab from './manageContentConnectionsTabs/ManageReceiptsTab';
import Document from '../../../../../../../components/icons/Document';
import { ManageContentConnectionsTabProps } from './types.manageContentConnectionsDialog';
import Receipt from '../../../../../../../components/icons/Receipt';
import Note from '../../../../../../../components/icons/Note';
import ManageNotesTab from './manageContentConnectionsTabs/ManageNotesTab';
import Product from '../../../../../../../components/icons/Product';
import Timeline from '../../../../../../../components/icons/Timeline';
import Image from '../../../../../../../components/icons/Image';
import Task from '../../../../../../../components/icons/Task';
import ManageProductsTab from './manageContentConnectionsTabs/ManageProductsTab';
import ManagePhotosTab from './manageContentConnectionsTabs/ManagePhotosTab';
import { useGetEntityTypeNames } from '../../../../../../../constants/entityTypeName';
import {
  getInitialConnections,
  generateEntityContentConnectionsPatchActionData,
} from '../../../utils/contentConnections';
import getEntityPossiblyConnectionTypes from '../../../utils/entityPossiblyConnectionTypes';
import useGetAddEntityConnectionAction from '../../../hooks/useGetAddEntityConnectionAction';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import useGetAddEntityConnectionInitialData from '../../../hooks/useGetAddEntityConnectionInitialData';
import { useGetBoxShadows } from '../../../../../../../hooks/useScrollWithShadow';
import Building from '../../../../../../../components/icons/Building';
import BuildingPart from '../../../../../../../components/icons/BuildingPart';
import Room from '../../../../../../../components/icons/Room';
import Outdoor from '../../../../../../../components/icons/Outdoor';
import ManageTimelineEntriesTab from './manageContentConnectionsTabs/ManageTimelineEntriesTab';
import ManageBuildingsTab from './manageContentConnectionsTabs/ManageBuildingsTab';
import ManageTasksTab from './manageContentConnectionsTabs/ManageTasksTab';
import HIDDialogTitle from '../../../../../../../components/dialogs/HIDDialogTitle';
import HIDDialogContent from '../../../../../../../components/dialogs/HIDDialogContent';
import HIDDialogActions from '../../../../../../../components/dialogs/HIDDialogActions';
import Wallet from '../../../../../../../components/icons/Wallet';
import ManageRecurringExpensesTab from './manageContentConnectionsTabs/ManageRecurringExpensesTab';

type ManageContentConnectionsEntity = {
  entityType: EntityType;
  Icon: FC;
  TabContent: FC<ManageContentConnectionsTabProps>
};

const MANAGE_CONTENT_CONNECTIONS_ENTITIES: Array<ManageContentConnectionsEntity> = [
  {
    entityType: EntityType.PRODUCT,
    Icon: Product,
    TabContent: ManageProductsTab,
  },
  {
    entityType: EntityType.DOCUMENT,
    Icon: Document,
    TabContent: ManageDocumentsTab,
  },
  {
    entityType: EntityType.RECEIPT,
    Icon: Receipt,
    TabContent: ManageReceiptsTab,
  },
  {
    entityType: EntityType.NOTE,
    Icon: Note,
    TabContent: ManageNotesTab,
  },
  {
    entityType: EntityType.PHOTO,
    Icon: Image,
    TabContent: ManagePhotosTab,
  },
  {
    entityType: EntityType.TASK,
    Icon: Task,
    TabContent: ManageTasksTab,
  },
  {
    entityType: EntityType.BUILDING,
    Icon: Building,
    TabContent: (props: ManageContentConnectionsTabProps) =>
      <ManageBuildingsTab buildingEntityType={EntityType.BUILDING} {...props} />,
  },
  {
    entityType: EntityType.BUILDING_PART,
    Icon: BuildingPart,
    TabContent: (props: ManageContentConnectionsTabProps) =>
      <ManageBuildingsTab buildingEntityType={EntityType.BUILDING_PART} {...props} />,
  },
  {
    entityType: EntityType.ROOM,
    Icon: Room,
    TabContent: (props: ManageContentConnectionsTabProps) =>
      <ManageBuildingsTab buildingEntityType={EntityType.ROOM} {...props} />,
  },
  {
    entityType: EntityType.OUTDOOR,
    Icon: Outdoor,
    TabContent: (props: ManageContentConnectionsTabProps) =>
      <ManageBuildingsTab buildingEntityType={EntityType.OUTDOOR} {...props} />,
  },
  {
    entityType: EntityType.TIMELINE_ENTRY,
    Icon: Timeline,
    TabContent: ManageTimelineEntriesTab,
  },
  {
    entityType: EntityType.RECURRING_EXPENSE,
    Icon: Wallet,
    TabContent: ManageRecurringExpensesTab,
  },
];

type GetManageContentConnectionsEntities = (supportedTypes?: Array<EntityType>) => Array<ManageContentConnectionsEntity>;
const getManageContentConnectionsEntities: GetManageContentConnectionsEntities = (supportedTypes) => supportedTypes?.length
  ? MANAGE_CONTENT_CONNECTIONS_ENTITIES.filter((tab) => supportedTypes.includes(tab.entityType))
  : MANAGE_CONTENT_CONNECTIONS_ENTITIES;

type TabListScrollButtonProps = {
  direction: 'left' | 'right';
  disabled: boolean;
  slots?: any;
  slotsProps?: any;
};

const TablistScrollButton: FCC<TabListScrollButtonProps> = ({
  direction,
  disabled,
  sx,
  ...rest
}) => {
  const cleanProps = R.omit(['slots', 'slotsProps'], rest);

  const { leftBoxShadow, rightBoxShadow } = useGetBoxShadows();

  return (
    <IconButton
      {...cleanProps}
      sx={{
        ...sx,
        borderRadius: 0,
        boxShadow: disabled ? 'none' : direction === 'left' ? rightBoxShadow : leftBoxShadow,
      }}
    >
      {disabled
        ? <MUIIcon />
        : direction === 'left' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
    </IconButton>
  );
};

export enum ManageContentConnectionsMode {
  ADD = 'add',
  SELECT = 'select',
}
type ManageContentConnectionsModeData = {
  type: ManageContentConnectionsMode.ADD;
} | {
  type: ManageContentConnectionsMode.SELECT;
  onSelect: (selectedItemsMap: Record<string, Array<string>>) => void;
  customConnectionTypes?: Array<EntityType>;
};

export type ManageContentConnectionsDialogProps = HIDDialogProps & {
  activeEntityType?: string;
  entityType: EntityType,
  mode: ManageContentConnectionsModeData;
  entity: Required<HIDEntityId>;
  onConnectionsAdded?: () => void;
};

const ManageContentConnectionsDialog: FC<ManageContentConnectionsDialogProps> = ({
  activeEntityType: activeTabProp,
  entityType,
  entity,
  open,
  mode,
  onClose,
  onConnectionsAdded,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'photos']);

  const { isDownSm } = useBreakPointsSizes();

  const possiblyConnectionTypes = getEntityPossiblyConnectionTypes(entityType);

  const entityTypeConnectionTypes = mode.type === ManageContentConnectionsMode.SELECT
    ? mode.customConnectionTypes
      ? possiblyConnectionTypes.filter((type) => mode.customConnectionTypes?.includes(type))
      : possiblyConnectionTypes
    : possiblyConnectionTypes;
  const contentConnectionsEntities = getManageContentConnectionsEntities(entityTypeConnectionTypes);

  const [activeTab, setActiveTab] = useState<string>('');
  const handleChange = (_event: React.SyntheticEvent, newTab: EntityType) => setActiveTab(newTab);

  useEffect(() => {
    if (open) {
      const currentActiveTab = activeTabProp && entityTypeConnectionTypes.includes(activeTabProp)
        ? activeTabProp
        : R.head(entityTypeConnectionTypes) as EntityType;
      setActiveTab(currentActiveTab);
    } else {
      setActiveTab('');
    }
  }, [open]);

  const entityId = entity?.id;

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: connectionsMap,
    isLoading: areConnectionsLoading,
    isSuccess: areConnectionsFetched,
  } = useGetEntityConnectionsQuery(
    propertyId && entityId
      ? {
        propertyId,
        entityId,
        entityType,
      }
      : skipToken,
  );

  const [selectedContentConnections, setSelectedContentConnections] = useState<Record<string, Array<string>>>(
    {},
  );

  useEffect(
    () => setSelectedContentConnections(getInitialConnections(connectionsMap)),
    [areConnectionsFetched, open],
  );

  const handleClose = onClose;

  const [patchEntityConnections, { isLoading: patchingEntityConnections }] = usePatchEntityConnectionsMutation();

  const handleSelect = () => {
    if (mode.type === 'select') {
      mode.onSelect(selectedContentConnections);
      handleClose();
    } else {
      if (!entityId) {
        handleClose();
        return;
      }

      const initialValues = getInitialConnections(connectionsMap);
      const selectedValues = selectedContentConnections;

      const actions = generateEntityContentConnectionsPatchActionData(initialValues, selectedValues);

      if (R.isEmpty(actions) || !propertyId) {
        handleClose();
        return;
      }

      const data = {
        propertyId,
        entityId,
        entityType,
        actions,
      };

      patchEntityConnections(data)
        .then(() => {
          handleClose();
          if (onConnectionsAdded) {
            onConnectionsAdded();
          }
        });
    }
  };

  const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedContentConnections((prev) => ({ ...prev, [activeTab]: rowSelectionModel as string[] }));
  };
  // eslint-disable-next-line deprecation/deprecation
  const entityNames = useGetEntityTypeNames();

  const getTabLabel = useCallback((entityType: EntityType, Icon: Icon) => {
    const selectedItems = selectedContentConnections[entityType];

    return (
      <Stack alignItems="center" spacing={1}>
        <Icon color="primary" />
        <Typography
          variant={isDownSm ? 'body2' : 'body1'}
        >
          {`${entityNames[entityType]}${selectedItems?.length ? `(${selectedItems.length})` : ''}`}
        </Typography>
      </Stack>
    );
  }, [isDownSm, selectedContentConnections]);

  const addNewConnectionAction = useGetAddEntityConnectionAction(activeTab as EntityType);
  const getEntityMapper = useGetAddEntityConnectionInitialData(entityType);

  const handleAddNew = () => {
    handleClose();
    if (entityId) {
      const entityInitialData = getEntityMapper(activeTab as EntityType, entity);
      addNewConnectionAction(
        [{
          entityType,
          entityId,
        }],
        R.fromPairs(entityInitialData.map((item) => [item.key, item.value])),
      );
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isDownSm}
      maxWidth={contentConnectionsEntities.length > 6 ? 'lg' : 'md'}
      open={open}
      onClose={handleClose}
    >
      <TabContext value={activeTab}>
        <HIDDialogTitle
          title={t('common:manage_connections')}
          onClose={handleClose}
        >
          <TabList
            allowScrollButtonsMobile
            ScrollButtonComponent={TablistScrollButton}
            sx={{
              marginTop: theme.spacing(2.5),
              paddingX: isDownSm ? theme.spacing(0) : theme.spacing(2),
            }}
            variant="scrollable"
            onChange={handleChange}
          >
            {
              contentConnectionsEntities.map((entity) => (
                <Tab
                  key={entity.entityType}
                  label={getTabLabel(entity.entityType, entity.Icon)}
                  style={{
                    padding: theme.spacing(1),
                  }}
                  value={entity.entityType}
                />
              ))
            }
          </TabList>
        </HIDDialogTitle>
        <HIDDialogContent sx={{ paddingTop: 0 }}>
          <Stack
            display="flex"
            flexGrow={1}
            sx={{ minHeight: '50vh' }}
          >
            {
              contentConnectionsEntities.map((entity) => (
                <TabPanel key={entity.entityType} style={{ padding: 0 }} value={entity.entityType}>
                  <entity.TabContent
                    loading={areConnectionsLoading}
                    selectedItems={selectedContentConnections[entity.entityType] || []}
                    onSelect={handleRowSelectionModelChange}
                  />
                </TabPanel>
              ))
            }
          </Stack>
        </HIDDialogContent>
        <HIDDialogActions>
          <HIDButton
            color="secondary"
            disabled={false}
            onClick={handleClose}
          >
            {t('common:cancel')}
          </HIDButton>
          <HIDButton
            color="secondary"
            onClick={handleAddNew}
          >
            {t('common:add_label')}
          </HIDButton>
          <HIDButton
            disabled={false}
            loading={patchingEntityConnections}
            onClick={handleSelect}
          >
            {t('common:save')}
          </HIDButton>
        </HIDDialogActions>
      </TabContext>
    </Dialog>
  );
};

export default ManageContentConnectionsDialog;
