import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { EntityContentConnection } from '@house-id/houseid-types/dist/content/entityContentConnection';
import { useNavigate } from 'react-router';

import { FCC } from '../../../../../types/common';
import getEntityPossiblyConnectionTypes from '../utils/entityPossiblyConnectionTypes';
import HIDButton, { HIDButtonProps } from '../../../../../components/buttons/HIDButton';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import { UnavailableFeature } from '../../../components/dialogs/OpenMobileAppDialog';
import useGetEntityInfo from '../hooks/useGetEntityInfo';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import EntityConnectionsGroupItem from './EntityConnectionsGroupItem';
import { mapConnectionsFullModelToIds } from '../utils/contentConnections';
import { ManageContentConnectionsMode } from './dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';
import { Add } from '@mui/icons-material';

type EntityConnectionsGroupProps = {
  buttonColor?: HIDButtonProps['color'];
  entityId?: string,
  entityType: EntityType,
  DescriptionComponent?: ReactNode;
  title?: string;
  sections: Array<EntityType>;
  connectionsMap: Partial<Record<EntityType, EntityContentConnection[]>>
  onChange: (data: Record<string, Array<string>>) => void;
  addButtonText?: string;
};

const EntityConnectionsGroup: FCC<EntityConnectionsGroupProps> = ({
  entityId,
  entityType,
  DescriptionComponent,
  title,
  addButtonText,
  sections,
  connectionsMap,
  buttonColor,
  onChange,
  sx,
}) => {
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const possibleConnections = getEntityPossiblyConnectionTypes(entityType)
    .filter((possibleEntityType) => sections.includes(possibleEntityType));

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const addNewConnection = () => {
    if (entityId) {
      openManageConnectionsDialog({
        entityType: EntityType.DEDUCTION,
        entity: { id: entityId },
        mode: {
          type: ManageContentConnectionsMode.SELECT,
          onSelect: (values: Record<string, string[]>) => {
            const ids: Record<string, string[]> = mapConnectionsFullModelToIds(connectionsMap);

            const merged: Record<string, string[]> = R.mergeWith(R.concat, ids, values);
            onChange(merged);
          },
          customConnectionTypes: sections,
        },
      });
    }
  };

  const handleDeleteConnection = (connectionId: string, entityType: EntityType) => {
    const ids = mapConnectionsFullModelToIds(connectionsMap);
    onChange({
      ...ids,
      [entityType]: ids[entityType].filter((id) => id !== connectionId),
    });
  };

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);
  const getEntityInfo = useGetEntityInfo();

  const handleClick = (id: string, connectionEntityType: string) => {
    const viewEntityConnection = getEntityInfo(connectionEntityType as EntityType);

    if (viewEntityConnection?.getViewLink) {
      navigate(getPathWithPropertyIdOrInit(viewEntityConnection?.getViewLink, { propertyId, id }));
    } else {
      openMobileAppDialog({
        unavailableFeature: UnavailableFeature.VIEW_ENTITY_CONNECTION,
      });
    }
  };

  const hasConnections = !R.isNil(connectionsMap) && !R.isEmpty(connectionsMap);

  const showSectionName = Object.keys(connectionsMap).length > 1;

  return (
    <Stack gap={2.5} sx={sx}>
      {title ? (<Typography variant="h6">{title}</Typography>) : null}
      {DescriptionComponent}
      {hasConnections
        ? (
          <Stack gap={2.5}>
            {
              R.toPairs(connectionsMap as Record<EntityType, EntityContentConnection[]>)
                .filter(([connectionEntityType]) => possibleConnections.includes(connectionEntityType))
                .map(([connectionEntityType, connections]) => (
                  <Stack key={connectionEntityType}>
                    {showSectionName
                      ? (
                        <Typography variant="subtitle1">
                          {getEntityInfo(connectionEntityType).namePlural}
                        </Typography>
                      ) : null}
                    <Grid
                      container
                      spacing={1.5}
                      sx={{ marginTop: 1.5 }}
                    >
                      {connections.map((connection) => (
                        <Grid
                          item
                          key={`${connection.type} ${connection.entity.id}`}
                          lg={connections.length > 1 ? 6 : 12}
                          xxs={12}
                        >
                          <EntityConnectionsGroupItem
                            entityType={connection.type as EntityType}
                            name={connection.entity.name}
                            thumbnailUrl={connection.entity.image?.thumbnailUrl}
                            onClick={() => handleClick(connection.entity.id, connection.type)}
                            onDelete={() => handleDeleteConnection(connection.entity.id, connection.type as EntityType)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                ))
            }
          </Stack>
        )
        : null}
      <HIDButton
        Icon={Add}
        color={buttonColor}
        sx={{ alignSelf: 'flex-end' }}
        title={addButtonText}
        onClick={addNewConnection}
      />
    </Stack>
  );
};

export default EntityConnectionsGroup;
