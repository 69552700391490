import { useEffect } from 'react';
import {
  matchPath,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  HIDEventCategory,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import RouteNames from '../../../routes/RouteNames';
import AdminRouteNames from '../../Admin/navigation/AdminRouteNames';

type AllRoutes = RouteNames | AdminRouteNames;

const routeNamesHidEventCategory: Record<AllRoutes, HIDEventCategory> = {
  // default
  [RouteNames.INIT]: HIDEventCustomCategory.DEFAULT,
  [RouteNames.HOME]: HIDEventCustomCategory.DEFAULT,
  [RouteNames.PROPERTY_HOME]: HIDEventCustomCategory.DEFAULT,
  [RouteNames.ROOT]: HIDEventCustomCategory.DEFAULT,
  [RouteNames.ABOUT]: HIDEventCustomCategory.DEFAULT,
  [RouteNames.DECLARATION_HELP_TERMS_AND_PRIVACY_POLICY]: HIDEventCustomCategory.DEFAULT,
  // partners
  [RouteNames.PARTNERS]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_LOGIN]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_HOME]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_FORUM]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_FORUM_TOPIC]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_FORUM_THREAD]: HIDEventCustomCategory.PARTNERS,
  [RouteNames.PARTNERS_FORUM_EXPERTS]: HIDEventCustomCategory.PARTNERS,
  // auth
  [RouteNames.LOGIN]: HIDEventCustomCategory.AUTH,
  [RouteNames.LOGIN_SECOND_FACTOR]: HIDEventCustomCategory.AUTH,
  [RouteNames.SIGN_UP]: HIDEventCustomCategory.AUTH,
  [RouteNames.FORGOT_PASSWORD]: HIDEventCustomCategory.AUTH,
  [RouteNames.RESET_PASSWORD]: HIDEventCustomCategory.AUTH,
  [RouteNames.RESET_PASSWORD_ONBOARDING]: HIDEventCustomCategory.AUTH,

  // onboarding
  [RouteNames.ONBOARDING]: HIDEventCustomCategory.AUTH,
  [RouteNames.INVITE_CODE]: HIDEventCustomCategory.AUTH,
  // property
  [RouteNames.PROPERTY_PROGRESS]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_PROGRESS_SPECIFICATION]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_BLUEPRINTS]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_PURCHASE]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_TODO]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_PLANNING]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_DOCUMENTATION]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_PRODUCTS]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_INSURANCE_AND_SAFETY]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_FINANCE]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_SETTINGS]: EntityType.PROPERTY,
  [RouteNames.PROPERTY_STORAGE]: EntityType.PROPERTY,
  // selling property
  [RouteNames.SELLING_PROPERTY_CHECKLIST]: EntityType.PROPERTY,
  [RouteNames.SELLING_PROPERTY_INFO]: EntityType.PROPERTY,
  [RouteNames.SELLING_PROPERTY_ARCHIVE]: EntityType.PROPERTY,
  [RouteNames.SELLING_PROPERTY_TRANSFER]: EntityType.PROPERTY,
  // external services
  [RouteNames.EXTERNAL_SERVICES]: EntityType.EXTERNAL_SERVICE,
  // bonus offers
  [RouteNames.BONUS_OFFERS]: EntityType.BONUS_OFFER,
  [RouteNames.BONUS_OFFERS_MY_BONUS_POINTS]: EntityType.BONUS_OFFER,
  [RouteNames.BONUS_OFFERS_LOGS]: EntityType.BONUS_OFFER,
  [RouteNames.BONUS_OFFERS_FROM_MOVE_IN]: EntityType.BONUS_OFFER,
  [RouteNames.BONUS_OFFER_DETAILS]: EntityType.BONUS_OFFER,
  // search
  [RouteNames.SEARCH_RESULTS]: HIDEventCustomCategory.SEARCH,
  // receipts
  [RouteNames.RECEIPTS_CATEGORIES]: EntityType.RECEIPT,
  [RouteNames.RECEIPTS]: EntityType.RECEIPT,
  [RouteNames.RECEIPT]: EntityType.RECEIPT,
  [RouteNames.RECEIPT_CREATE]: EntityType.RECEIPT,
  [RouteNames.RECEIPT_UPDATE]: EntityType.RECEIPT,
  // documents
  [RouteNames.DOCUMENTS_CATEGORIES]: EntityType.DOCUMENT,
  [RouteNames.DOCUMENTS]: EntityType.DOCUMENT,
  [RouteNames.DOCUMENT]: EntityType.DOCUMENT,
  [RouteNames.DOCUMENT_CREATE]: EntityType.DOCUMENT,
  [RouteNames.DOCUMENT_UPDATE]: EntityType.DOCUMENT,
  // content files
  [RouteNames.CONTENT_FILES]: EntityType.CONTENT_FILE,
  // notes
  [RouteNames.NOTES]: EntityType.NOTE,
  [RouteNames.NOTE]: EntityType.NOTE,
  [RouteNames.NOTE_CREATE]: EntityType.NOTE,
  [RouteNames.NOTE_UPDATE]: EntityType.NOTE,
  // photos
  [RouteNames.PHOTOS]: EntityType.PHOTO,
  [RouteNames.PHOTOS_CREATE]: EntityType.PHOTO,
  [RouteNames.PHOTOS_UPDATE]: EntityType.PHOTO,
  // products
  [RouteNames.PRODUCTS]: EntityType.PRODUCT,
  [RouteNames.PRODUCT]: EntityType.PRODUCT,
  [RouteNames.PRODUCT_CREATE]: EntityType.PRODUCT,
  [RouteNames.PRODUCT_UPDATE]: EntityType.PRODUCT,
  // timeline
  [RouteNames.TIMELINE]: EntityType.TIMELINE_ENTRY,
  [RouteNames.TIMELINE_EVENT]: EntityType.TIMELINE_ENTRY,
  [RouteNames.TIMELINE_CREATE_EVENT]: EntityType.TIMELINE_ENTRY,
  [RouteNames.TIMELINE_UPDATE_EVENT]: EntityType.TIMELINE_ENTRY,
  // suggestions
  [RouteNames.SUGGESTIONS]: EntityType.SUGGESTION,
  [RouteNames.SUGGESTION_CATEGORIES]: EntityType.SUGGESTION,
  [RouteNames.SUGGESTION_SETTINGS]: EntityType.SUGGESTION,
  // messages
  [RouteNames.MESSAGES]: EntityType.MESSAGE,
  [RouteNames.MESSAGE]: EntityType.MESSAGE,
  // user settings
  [RouteNames.USER_SETTINGS]: HIDEventCustomCategory.USER,
  [RouteNames.USER_SETTINGS_ONBOARDING]: HIDEventCustomCategory.USER,
  [RouteNames.USER_DETAILS]: HIDEventCustomCategory.USER,
  [RouteNames.USER_DETAILS_ONBOARDING]: HIDEventCustomCategory.USER,
  [RouteNames.USER_STORAGE]: HIDEventCustomCategory.USER,
  // feedback & support
  [RouteNames.FEEDBACK]: HIDEventCustomCategory.SUPPORT,
  [RouteNames.FEEDBACK_ONBOARDING]: HIDEventCustomCategory.SUPPORT,
  // forum
  [RouteNames.FORUM]: HIDEventCustomCategory.FORUM,
  [RouteNames.FORUM_TOPIC]: HIDEventCustomCategory.FORUM,
  [RouteNames.FORUM_THREAD]: HIDEventCustomCategory.FORUM,
  [RouteNames.FORUM_EXPERTS]: HIDEventCustomCategory.FORUM,
  [RouteNames.FORUM_MY_QUESTIONS]: HIDEventCustomCategory.FORUM,
  [RouteNames.FORUM_MY_BOOKMARKS]: HIDEventCustomCategory.FORUM,
  // subscription plans
  [RouteNames.SUBSCRIPTION_PLANS]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLAN]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLAN_BUY_SUBSCRIPTION]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER_ERROR]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ERROR]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [RouteNames.SUBSCRIPTION_PLANS_SUBSCRIPTION_PAYMENTS_HISTORY]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  // move
  [RouteNames.MOVE]: HIDEventCustomCategory.MOVE,
  // finances
  [RouteNames.FINANCES]: HIDEventCustomCategory.FINANCE_COMMON,
  // budgets
  [RouteNames.BUDGET_CATEGORY]: EntityType.BUDGET,
  [RouteNames.BUDGETS_OVERVIEW]: EntityType.BUDGET,
  [RouteNames.BUDGETS_CREATE]: EntityType.BUDGET,
  [RouteNames.BUDGETS_UPDATE]: EntityType.BUDGET,
  // recurring expenses
  [RouteNames.RECURRING_EXPENSES_CATEGORIES]: EntityType.RECURRING_EXPENSE,
  [RouteNames.RECURRING_EXPENSES_CATEGORY]: EntityType.RECURRING_EXPENSE,
  [RouteNames.RECURRING_EXPENSES_BANK_ACCOUNTS]: EntityType.RECURRING_EXPENSE,
  [RouteNames.RECURRING_EXPENSE]: EntityType.RECURRING_EXPENSE,
  [RouteNames.RECURRING_EXPENSE_UPDATE]: EntityType.RECURRING_EXPENSE,
  // valuation
  [RouteNames.VALUATIONS]: EntityType.VALUATION,
  [RouteNames.VALUATION_OVERVIEW]: EntityType.VALUATION,
  [RouteNames.VALUATION_CREATE]: EntityType.VALUATION,
  [RouteNames.VALUATION_UPDATE]: EntityType.VALUATION,
  [RouteNames.VALUATION_HISTORY]: EntityType.VALUATION,
  [RouteNames.VALUATION_BOOK_MEETING]: EntityType.VALUATION,
  // declaration
  [RouteNames.BUY_DECLARATION_HELP]: EntityType.DECLARATION,
  [RouteNames.DECLARATION_CALCULATIONS]: EntityType.DECLARATION,
  [RouteNames.DECLARATION_CALCULATIONS_DEDUCTIONS]: EntityType.DECLARATION,
  [RouteNames.DECLARATION_HELP_OVERVIEW]: EntityType.DECLARATION,
  [RouteNames.UPDATE_DECLARATION_SETTINGS]: EntityType.DECLARATION,
  [RouteNames.DEDUCTION_CREATE]: EntityType.DECLARATION,
  [RouteNames.DEDUCTION_UPDATE]: EntityType.DECLARATION,
  // admin
  [AdminRouteNames.ADMIN_HOME]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.LABELING_QUEUES]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.LABELING_QUEUE_TASKS]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.VIEW_LABELING_TEMPLATES_TASK]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.ADD_LABELING_QUEUE_TASK_INVOICE_DATA]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.ADD_LABELING_QUEUE_TASK_CASHIER_RECEIPT_DATA]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.EMAILS]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.UPLOAD_EMAIL]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.IMITATE_ACCOUNT]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.SURVEYS]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.SURVEY_GROUPS]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.SURVEY_GROUP_CRETE]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.SURVEY_GROUP_UPDATE]: HIDEventCustomCategory.ADMIN,
  [AdminRouteNames.CREATE_PROPERTY]: HIDEventCustomCategory.ADMIN,

  // consumables
  [RouteNames.CONSUMABLES]: HIDEventCustomCategory.CONSUMABLE,
  [RouteNames.CONSUMABLE_BUY]: HIDEventCustomCategory.CONSUMABLE,
  [RouteNames.CONSUMABLE_ORDER]: HIDEventCustomCategory.CONSUMABLE,
  [RouteNames.CONSUMABLE_ORDER_ERROR]: HIDEventCustomCategory.CONSUMABLE,

  // landing
  [RouteNames.LANDINGS]: HIDEventCustomCategory.ONBOARDING,
  [RouteNames.DECLARATION_HELP_SF_SELLER_CALL_CENTER_INSTRUCTIONS]: HIDEventCustomCategory.ONBOARDING,
  [RouteNames.DECLARATION_HELP_READ_MORE_ABOUT_THE_SERVICE]: HIDEventCustomCategory.ONBOARDING,
  [RouteNames.DECLARATION_HELP_ONBOARDING]: EntityType.DECLARATION,

  [RouteNames.ANY_PAGE]: HIDEventCustomCategory.DEFAULT,
};

// Define your route templates
const routes = Object.keys(routeNamesHidEventCategory);

function getRouteTemplate(pathname: string) {
  for (const route of routes) {
    const match = matchPath(route, pathname);

    if (match) {
      return route; // Return the matched route template
    }
  }
  return null; // No matching route found
}

function replaceGuidInPath(path: string) {
  // Regular expression to match GUIDs in the path
  const guidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

  // Replace the GUIDs in the path with the placeholder '[guid]'
  return path.replace(guidPattern, ':propertyId');
}

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pathTemplate = replaceGuidInPath(location.pathname);
    const routeTemplate = getRouteTemplate(pathTemplate);
    const hidCategory: HIDEventCategory = routeTemplate
      ? routeNamesHidEventCategory[routeTemplate as AllRoutes]
      : HIDEventCustomCategory.DEFAULT;

    ReactGA.send({
      hitType: 'pageview',
      page: pathTemplate,
      hidCategory,
    });
  }, [location]);
};

export default usePageTracking;
